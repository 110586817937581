import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"

export default () => (
  <Layout>
    <SEO title="Thank You" />
    <h1 className="tracking-wide">Thank you!</h1>
    <p className="text-gray-700 text-base pt-4">
      We have received your message and will respond as soon as possible.
    </p>
    <div className="py-4 text-sm text-primary">
      <Link to="/">
        <FontAwesomeIcon icon={faLongArrowAltLeft} />
        &nbsp;Back to homepage
      </Link>
    </div>
  </Layout>
)
